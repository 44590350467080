import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { HelioCheckout } from '@heliofi/checkout-react';
import { FormattedMessage } from 'react-intl';

import { ModalHOC } from 'common';
import { PageLayout, MoneyCell, DefaultButton, BasicModal } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { TwoStepEmailForm, TwoStepGoogleForm } from 'auth/twoStepAuth/_components';
import { verifyWithdrawalRequest, verifyWithdrawalResend } from 'auth/twoStepAuth/_redux';

import UserAmountEditHistory from '../UserAmountEditHistory';
import PaymentsInfoPanel from '../PaymentsInfoPanel';
import PaymentProofForm from '../PaymentProofForm';
import SecondPaymentsForm from '../SecondPaymentsForm';

import { staticStyles, getDynamicStyles } from './style';

const PaymentsConfirmationPanel = ({
  payments,
  profile,
  loadPayments,
  getPaymentsInvoice,
  loading,
  match,
  redirect,
  addons,
  theme,
  showModal,
  cancelWithdrawal,
  isConfirmationMessageResent,
}) => {
  const onReloadClick = () => loadPayments(payments.id);
  const isDeposit = ['wallet_deposit', 'deposit'].includes(payments?.operation);
  const isWithdraw = ['wallet_withdraw', 'withdraw'].includes(payments?.operation);
  const hasAdditionalForm = !payments?.paymentMethodRequest?.requisitesForm.properties.null;

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [tfaModalOpen, setTfaModalOpen] = useState(false);
  const componentRef = useRef(null);

  const handleTfaModalToggle = () => {
    setTfaModalOpen(isOpen => !isOpen);
  };

  useEffect(() => {
    loadPayments(match.params.id);
  }, [match.params.id]);

  useEffect(() => {
    if (isConfirmationMessageResent) setMessageModalOpen(true);
  }, [isConfirmationMessageResent]);

  const submitPayments = () => {
    if (payments.redirectSpec.spec) {
      const { fields, url, method = 'POST' } = payments.redirectSpec.spec;

      // Проверяем, существует ли элемент формы, и если да, удаляем его
      const existingForm = document.forms.submitRequest;
      if (existingForm) {
        existingForm.parentNode.removeChild(existingForm);
      }

      let form = `<form action="${url}" name="submitRequest" method=${method} style="display: none;">`;
      Object.keys(fields).forEach(key => {
        form += `<input type="text" name="${key}" value="${fields[key]}" />`;
      });
      form += '</form>';

      const formEl = document.createElement('div');
      formEl.innerHTML = form;
      document.body.appendChild(formEl);
      document.forms.submitRequest.submit();
    }
  };

  const cancelWithdrawalClick = () => {
    showModal({
      caption: { id: 'confirmationToCancelWithdrawal' },
      content: {
        id: 'confirmText',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => cancelWithdrawal(payments.id),
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  const finishPayment = () => {
    setInfoModalOpen(true);
  };

  const closeModal = () => {
    setInfoModalOpen(false);
    setTimeout(() => {
      loadPayments(match.params.id);
    }, 1000);
  };

  useEffect(() => {
    if (redirect) {
      window.location = '/';
    }
  }, [redirect]);

  const captionSlug = () => {
    if (isDeposit) {
      return 'clientDeposit';
    }
    if (isWithdraw) {
      return 'clientWithdraw';
    }
    return 'justCompensation';
  };

  const paymentFormVals = {
    id: payments?.paymentMethodRequest?.id,
    invoiceEnabled: payments?.paymentMethodRequest?.invoiceEnabled,
    paymentMethod: payments?.paymentMethodRequest?.paymentMethod,
    request: payments?.paymentMethodRequest?.request,
    saveRequisites: payments?.paymentMethodRequest?.saveRequisites,
    requisitesForm: payments?.paymentMethodRequest?.requisitesForm,
  };

  const showPaymentNotification =
    (payments?.redirectSpec && payments.redirectSpec.type !== 'image') ||
    (payments?.redirectSpec && payments.redirectSpec.type === 'image' && payments?.redirectSpec.spec.url);

  return (
    payments &&
    Number(match.params.id) === payments.id && (
      <PageLayout
        captionSlug={captionSlug()}
        extraCaptionText={payments.id || ''}
        showReload
        onReloadClick={onReloadClick}
        reloading={loading}>
        {showPaymentNotification && (
          <FormattedMessage id="justPaymentNotificate">{txt => <h3>{txt}</h3>}</FormattedMessage>
        )}
        {/* eslint-disable */}
        <div ref={componentRef}>
          {/* eslint-enable */}
          <PaymentsInfoPanel
            isDeposit={isDeposit}
            data={payments}
            addons={addons}
            paymentSystemInstruction={payments.paymentSystemInstruction}
          />
          {payments.instruction && (
            <ReactToPrint
              trigger={() => (
                <div
                  className="Payments__instruction"
                  dangerouslySetInnerHTML={{
                    __html: payments.instruction.text,
                  }}
                />
              )}
              content={() => componentRef}
            />
          )}

          {payments.redirectSpec && payments.redirectSpec.type === 'image' && (
            <>
              <div className="Payments__type-image-spec-wrap">
                {payments.redirectSpec.spec.image && (
                  <img src={`data:image/png;base64, ${payments.redirectSpec.spec.image}`} alt="crypto qr" />
                )}
                {payments.redirectSpec.spec.text && (
                  <div>
                    <FormattedMessage id="justInfo">{txt => <span>{txt}</span>} </FormattedMessage>
                    <div dangerouslySetInnerHTML={{ __html: payments.redirectSpec.spec.text }} />
                  </div>
                )}
              </div>
              <div className="Payments__buttons-wrap">
                {payments.redirectSpec.spec.url && (
                  <DefaultButton
                    textId="continueRequest"
                    onClick={() => window.open(payments.redirectSpec.spec.url)}
                    filled
                  />
                )}
                {isDeposit && <DefaultButton textId="justBack" type="button" onClick={() => window.history.back()} />}
              </div>
            </>
          )}

          {payments.widget && payments.paymentSystemSlug === 'Helio' && (
            <div className="Payments__widget">
              <HelioCheckout config={payments.widget} />
            </div>
          )}

          <div className="Payments__buttons-wrap">
            {payments.invoiceEnabled && isDeposit && (
              <DefaultButton textId="downloadInvoice" onClick={() => getPaymentsInvoice(payments.id)} />
            )}

            {/* eslint-disable */}
            {payments.redirectSpec && payments.redirectSpec.type === 'form' && (
              <>
                <DefaultButton textId="continueRequest" onClick={submitPayments} filled />
                {isDeposit && <DefaultButton textId="justBack" type="button" onClick={() => window.history.back()} />}
              </>
            )}

            {payments.redirectSpec &&
              payments.redirectSpec.type === 'url' &&
              payments.status !== 'deposited' &&
              payments.status !== 'failed' &&
              payments.status !== 'in_process' &&
              payments.status !== 'id_verification' && (
                <>
                  <DefaultButton
                    textId="continueRequest"
                    onClick={() => window.open(payments.redirectSpec.spec)}
                    filled
                  />
                  {isDeposit && <DefaultButton textId="justBack" type="button" onClick={() => window.history.back()} />}
                </>
              )}
            {/* eslint-enable */}
            {payments &&
              (payments.operation === 'withdraw' ||
                payments.operation === 'wallet_withdraw' ||
                payments.operation === 'waiting_approval') && (
                <>
                  {payments.cancelButton && (
                    <DefaultButton textId="justCancel" onClick={() => cancelWithdrawalClick()} butColor="error" />
                  )}
                  {payments.status === 'waiting_verification' && (
                    <>
                      <DefaultButton textId="justVerify" onClick={handleTfaModalToggle} filled />
                      <BasicModal
                        isOpen={tfaModalOpen}
                        modalClassName="TwoStepModal"
                        captionId="authTwoStepAuthentication"
                        onRequestClose={handleTfaModalToggle}>
                        {profile?.tfaSettings?.type === 'email' && (
                          <TwoStepEmailForm
                            isVerify
                            withdrawalId={payments.id}
                            onResend={() => verifyWithdrawalResend.request({ id: payments.id })}
                            onSubmit={verifyWithdrawalRequest}
                            onClose={handleTfaModalToggle}
                          />
                        )}
                        {profile?.tfaSettings?.type === 'totp' && (
                          <TwoStepGoogleForm
                            withdrawalId={payments.id}
                            onSubmit={verifyWithdrawalRequest}
                            onClose={handleTfaModalToggle}
                          />
                        )}
                      </BasicModal>
                    </>
                  )}
                  {payments.status === 'need_finish' && (
                    <DefaultButton textId="justFinish" onClick={() => finishPayment()} filled />
                  )}
                </>
              )}
          </div>

          {/* eslint-disable */}
          {payments.paymentProofEnabled && (
            <PaymentProofForm paymentId={payments.id} status={payments.paymentProofStatus} isDeposit={isDeposit} />
          )}
          {/* eslint-enable */}

          <UserAmountEditHistory accountId={match.params.id} />
        </div>
        <BasicModal
          captionId="justConfirmationMessageSent"
          isOpen={messageModalOpen}
          onRequestClose={() => setMessageModalOpen(false)}
        />
        <BasicModal
          captionId="justFinishWithdrawal"
          isOpen={infoModalOpen}
          onRequestClose={() => setInfoModalOpen(false)}>
          <SecondPaymentsForm
            paymentForm={paymentFormVals}
            onResetForm={() => setInfoModalOpen(false)}
            closeModal={closeModal}
            isModal
            addons={addons}
            history={window.history}
            isNullForm={!hasAdditionalForm}
            justFinish
          />
        </BasicModal>

        <style jsx global>
          {staticStyles}
        </style>
        <style jsx global>
          {dynamicStyles}
        </style>
      </PageLayout>
    )
  );
};

PaymentsConfirmationPanel.propTypes = {
  payments: PropTypes.shape({
    id: PropTypes.number.isRequired,
    operation: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    accountLogin: PropTypes.string.isRequired,
    amount: MoneyCell.propTypes.value,
    paymentMethodTitle: PropTypes.string.isRequired,
    paymentSystemIconUrl: PropTypes.string.isRequired,
    paymentSystemInstruction: PropTypes.string.isRequired,
    instruction: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    invoiceEnabled: PropTypes.bool.isRequired,
    paymentProofEnabled: PropTypes.bool.isRequired,
    redirectSpec: PropTypes.object,
    paymentMethodRequest: PropTypes.shape({
      id: PropTypes.number,
      invoiceEnabled: PropTypes.bool,
      paymentMethod: PropTypes.number,
      request: PropTypes.object,
      saveRequisites: PropTypes.bool,
      requisitesForm: PropTypes.shape({
        properties: PropTypes.object,
      }),
    }),
  }),
  isConfirmationMessageResent: PropTypes.bool,
  loadPayments: PropTypes.func.isRequired,
  getPaymentsInvoice: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  cancelWithdrawal: PropTypes.func.isRequired,
  showModal: PropTypes.func,
  redirect: PropTypes.bool.isRequired,
  addons: PropTypes.array.isRequired,
  theme: PropTypes.object,
  profile: PropTypes.object.isRequired,
};

PaymentsConfirmationPanel.defaultProps = {
  isConfirmationMessageResent: false,
  showModal: () => {},
  payments: {},
  theme: {},
};

const ThemedPaymentsConfirmationPanel = withTheme()(ModalHOC(PaymentsConfirmationPanel));

export default ThemedPaymentsConfirmationPanel;
export { PaymentsConfirmationPanel };
