import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { DefaultButton, RadioButton, ReduxSelectField } from 'common/components';

import { closeInvestmentNew } from 'client/investNew/_redux';

import staticStyles from './style';

const InvestCloseFormNew = ({
  isWalletAccountAddonEnabled,
  accounts,
  strategyId,
  toggleModal,
  handleSubmit,
  dispatch,
  submitting,
  invalid,
  error,
}) => {
  const [closeType, setCloseType] = useState('account');
  const submit = handleSubmit(({ account }) =>
    closeInvestmentNew(
      {
        strategy: strategyId,
        account,
      },
      dispatch
    )
  );

  const accountsOptions = accounts.map(account => ({
    value: account.id,
    label: `${account.login} (${account.balance.amount} ${account.balance.currency})`,
  }));

  return (
    <div className="Modal__content">
      <form className="InvestWithdrawForm" onSubmit={submit}>
        <div className="InvestWithdrawForm__inputs-inner">
          <FormattedMessage id="rammWithdraWantText" />
          <div className="InvestWithdrawForm__inputs-inner">
            <div>
              <RadioButton
                name="closeType"
                textId="rammWithdrawCloseStrategyDescNew"
                value="part"
                onChange={() => setCloseType('account')}
                checked={closeType === 'account'}
              />
              <div style={{ marginTop: '10px' }}>
                {closeType === 'account' && (
                  <Field
                    component={ReduxSelectField}
                    name="account"
                    maxMenuHeight={140}
                    textId="justAccount"
                    options={accountsOptions}
                    disabled={submitting}
                    validate={[required()]}
                    searchable
                  />
                )}
              </div>
            </div>
          </div>

          {isWalletAccountAddonEnabled && (
            <div>
              <RadioButton
                name="closeType"
                textId="justWithdrawToWallet"
                value="all"
                onChange={() => setCloseType('wallet')}
                checked={closeType === 'wallet'}
              />
              <p style={{ visibility: 'hidden' }} className="InvestWithdrawForm__sub">
                <FormattedMessage id="rammWithdrawCloseStrategyDescNew" />
              </p>
            </div>
          )}

          {error && <strong className="formError">{error}</strong>}
        </div>
        <div className="InvestWithdrawForm__btns">
          <DefaultButton
            textId="justClose"
            type="submit"
            loading={submitting}
            disabled={invalid || submitting}
            filled
          />

          <DefaultButton textId="justCancel" type="button" onClick={() => toggleModal()} />
        </div>
        <style jsx>{staticStyles}</style>
      </form>
    </div>
  );
};

InvestCloseFormNew.propTypes = {
  accounts: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isWalletAccountAddonEnabled: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

InvestCloseFormNew.defaultProps = {
  error: '',
};

export default compose(
  connect(state => ({
    isWalletAccountAddonEnabled: state.interfaceConfig.addons?.some(addon => addon === 'walletAccount'),
  })),
  reduxForm({
    form: 'InvestCloseFormNew',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
  })
)(InvestCloseFormNew);
export { InvestCloseFormNew };
